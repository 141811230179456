import {
	Loading,
	TabbedForm,
	FormTab,
	useEditContext,
	useGetIdentity,
	TextInput,
} from "react-admin";
import {
	RichTextInput,
	RichTextInputToolbar,
	FormatButtons,
} from "ra-input-rich-text";
import useOpenHAT from "../../Hooks/useOpenHAT";
import ServicesInput from "../../Fields/ServicesInput";
import RequestList from "../Requests/RequestList";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SmartInput from "../../Fields/SmartInput";
import Comment from "../../Components/Comment";
import Send from "../../Components/Send";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

//TODO - figure out how to get this to work with the smart input
// const isEmailRequired = (value, allValues) => {
// 	if (allValues.preferred_contact_method === "email" && !value) {
// 		return "Email is required if preferred contact method is email";
// 	}
// 	return undefined;
// };

// const isTelephoneRequired = (value, allValues) => {
// 	if (allValues.preferred_contact_method === "telephone" && !value) {
// 		return "Telephone is required if preferred contact method is telephone";
// 	}
// 	return undefined;
// };

const ClientForm = () => {
	//We call this form either from the CreateContext or the EditContext, the fields will be slightly different depending on whether or not there's a record
	const { record, isLoading: contextLoading } = useEditContext();
	const { identity, isLoading: identityLoading } = useGetIdentity();
	const { data: openHatRequests, isLoading: openHatRequestsLoading } =
		useOpenHAT({ clientId: record?.id });

	if (contextLoading || identityLoading || openHatRequestsLoading) {
		return <Loading />;
	}

	const blockNewRequests =
		identity.role.type === "agent" &&
		openHatRequests &&
		openHatRequests.length > 0;

	return (
		<TabbedForm>
			<FormTab label="Basic Details">
				{/* SUBMIT USER INFO WITH POST/PUT */}
				<TextInput
					source="user"
					label="User"
					disabled={true}
					type="hidden"
					sx={{
						display: "none",
					}}
					defaultValue={identity}
				/>
				<Grid container spacing={2} sx={{ width: "100%" }}>
					<Grid item xs={4}>
						<SmartInput name="first_name" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="last_name" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="birthdate" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="email" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="telephone" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="preferred_contact_method" />
					</Grid>
					<Grid item xs={12}>
						<SmartInput name="requesting_for" />
					</Grid>
					<Grid item xs={3}>
						<SmartInput name="existing_client" />
					</Grid>
					<Grid item xs={3}>
						<SmartInput name="primary_record" />
					</Grid>
					<Grid item xs={3}>
						<SmartInput name="compromised_safety" />
					</Grid>
					<Grid item xs={3}>
						<SmartInput name="client_consent" />
					</Grid>
				</Grid>
			</FormTab>
			<FormTab label="Source">
				<Grid container spacing={2} sx={{ width: "100%" }}>
					<Grid item xs={12}>
						<SmartInput name="self_identification" />
					</Grid>
					<Grid item xs={12}>
						<SmartInput name="service_provider" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="contact_name" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="contact_email" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="contact_telephone" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="contact_department" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="referral_reason" />
					</Grid>
					<Grid item xs={12}>
						<SmartInput name="source" />
					</Grid>
					<Grid item xs={12}>
						<SmartInput name="other_source" />
					</Grid>
				</Grid>
			</FormTab>
			<FormTab label="Contact">
				<Grid container spacing={2} sx={{ width: "100%" }}>
					<Grid item xs={12}>
						<SmartInput name="address" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="apt" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="city" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="postal_code" />
					</Grid>
					<Grid item xs={12}>
						<SmartInput name="no_address" />
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="telephone" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="primary_telephone_type" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="secondary_telephone" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="secondary_telephone_type" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="additional_telephone" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="additional_telephone_type" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="request_information" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="agree_to_messages" />
					</Grid>
				</Grid>
			</FormTab>
			<FormTab label="Demographics">
				<Grid container spacing={2} sx={{ width: "100%" }}>
					<Grid item xs={4}>
						<SmartInput name="has_ohip" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="ohip_number" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="vc" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="citizenship" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="other_citizenship" />
					</Grid>
					<Grid item xs={4}>
						<SmartInput name="country" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="time_in_canada" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="time_in_gta" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="gender" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="sexual_orientation" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="ethnicity" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="ethnicity_other" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="language" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="language_other" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="income" />
					</Grid>
					<Grid item xs={6}>
						<SmartInput name="dependents" />
					</Grid>
				</Grid>
			</FormTab>
			<FormTab label="Notes">
				<Grid container spacing={2} sx={{ width: "100%" }}>
					<Grid item xs={2}></Grid>
					<Grid item xs={8}>
						{record && record.notes && Array.isArray(record.notes)
							? record.notes.map((note, index) => {
									return <Comment key={index} note={note} />;
							  })
							: ""}
					</Grid>
					<Grid item xs={2}></Grid>
					<Grid item xs={2}></Grid>
					<Grid item xs={8}>
						<RichTextInput
							source="new_note"
							label="Add a new note"
							multiline
							fullWidth
							height="450px"
							sx={{ height: "450px", width: "100%" }}
							toolbar={
								<RichTextInputToolbar>
									<FormatButtons size="small" />
								</RichTextInputToolbar>
							}
						/>
					</Grid>
				</Grid>
			</FormTab>
			<FormTab label="Requests">
				<Grid container spacing={2} sx={{ width: "100%" }}>
					{record && record.id && (
						<Grid item xs={12}>
							<RequestList
								resource="requests"
								filter={{ client: record.id }}
								sx={{
									"& .RaList-content": {
										boxShadow: "none",
									},
								}}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<Typography variant="h6">Autres services demandés</Typography>
						<SmartInput name="other" />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6">Référer le client aux services</Typography>
						{blockNewRequests ? (
							<Alert severity="warning">
								Les parrainages sont mis en pause jusqu'à ce que l'équipe HAT
								ait terminé avec cet enregistrement.
							</Alert>
						) : (
							<ServicesInput source="new_requests" />
						)}
						{/* This is so stupid. For whatever reason the only way to stop the ServicesInput dropdown from flickering on staging is to add a second hidden version */}
						<div style={{ pointerEvents: "none", opacity: 0.0 }}>
							<ServicesInput source="new_requests" />
						</div>
					</Grid>
				</Grid>
			</FormTab>
			{record && record.sends && Array.isArray(record.sends) && (
				<FormTab label="Emails">
					<Grid container spacing={2} sx={{ width: "100%" }}>
						<Grid item xs={2}></Grid>
						<Grid item xs={8}>
							{record.sends.map((send, index) => {
								return <Send key={index} send={send} />;
							})}
						</Grid>
						<Grid item xs={2}></Grid>
					</Grid>
				</FormTab>
			)}
		</TabbedForm>
	);
};

export default ClientForm;
