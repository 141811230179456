import { Loading, useGetList } from "react-admin";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useInput } from "react-admin";

const ServicesInput = (props) => {
	const { data: serviceGroups, servicesLoading } = useGetList(
		"service-groups",
		{
			pagination: { page: 1, perPage: 200 },
		}
	);
	const { field } = useInput(props);

	if (servicesLoading || !serviceGroups) {
		return <Loading />;
	}

	const MenuItems = [];

	serviceGroups.reverse().forEach((serviceGroup) => {
		MenuItems.push({
			key: `group_${serviceGroup.id}`,
			value: serviceGroup.id,
			name: serviceGroup.name,
			type: "group",
		});
		serviceGroup.services.data.forEach((service) =>
			MenuItems.push({
				key: `service_${service.id}`,
				value: service.id,
				name: service.attributes.name,
				type: "service",
			})
		);
	});

	return (
		<Select
			multiple
			sx={{ width: "100%" }}
			{...field}
			value={field.value || []}
		>
			{MenuItems.map((item) => (
				<MenuItem
					key={item.key}
					value={item.type === "group" ? `group_${item.value}` : item.value}
					disabled={item.type === "group"}
					sx={{
						fontWeight: item.type === "group" ? "bold" : "book",
						textIndent: item.type === "group" ? 0 : 10,
					}}
				>
					{item.name}
				</MenuItem>
			))}
		</Select>
	);
};

export default ServicesInput;
